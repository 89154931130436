import BodyTextContainer from 'components/common/body-text-container';
import Section from 'components/common/section';
import { PortableText } from 'framework/sanity/portable-text';

import * as St from './styles';
import { ITwoColumnTextProps } from './types';

export default function TwoColumnText({
  columnOne,
  columnTwo,
  section,
  theme,
}: ITwoColumnTextProps) {
  return (
    <Section styles={section}>
      <St.TwoColumnContainer inset>
        <BodyTextContainer theme={theme} noGutter noPadding>
          <PortableText blocks={columnOne} renderContainerOnSingleChild />
        </BodyTextContainer>
        <BodyTextContainer theme={theme} noGutter noPadding>
          <PortableText blocks={columnTwo} renderContainerOnSingleChild />
        </BodyTextContainer>
      </St.TwoColumnContainer>
    </Section>
  );
}
