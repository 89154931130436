import styled from 'styled-components';

import Container from 'components/common/container';
import {
  DESKTOP_NAV_HEIGHT,
  MOBILE_NAV_HEIGHT,
} from 'components/common/header/constants';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

import { IEmbedBlockProps } from './types';

export const StyledContainer = styled(Container)<
  Pick<IEmbedBlockProps, 'maxWidth'>
>`
  display: grid;
  grid-gap: var(--site-gutter);
  justify-content: center;
  width: 100%;

  .embed-content {
    ${({ maxWidth }) => maxWidth !== 'standard' && 'justify-self: center;'}
    max-width: 90ch;
    font-size: var(--base-font-size);

    p:last-child {
      margin-bottom: 0;
    }
  }
`;

export const EmbedWrapper = styled.div<Pick<IEmbedBlockProps, 'layout'>>`
  ${({ layout }) => layout === 'embedBelowContent' && 'order: 1;'}
`;

const ASPECT_RATIOS = {
  landscape: 16 / 9,
  portrait: 9 / 16,
  square: 1,
};

export const Embed = styled.div<
  Pick<IEmbedBlockProps, 'aspectRatio' | 'maxWidth'>
>`
  position: relative;
  aspect-ratio: ${({ aspectRatio }) => ASPECT_RATIOS[aspectRatio]};
  max-height: calc(100vh - ${MOBILE_NAV_HEIGHT}px - var(--site-gutter) * 2);
  width: ${({ maxWidth }) => theme.pageWidth[maxWidth]}px;
  max-width: calc(100vw - var(--site-gutter) * 2);
  margin-inline: auto;

  ${desktop} {
    max-height: calc(100vh - ${DESKTOP_NAV_HEIGHT}px - var(--site-gutter) * 2);
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
`;
