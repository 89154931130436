import Link from 'next/link';
import { FC } from 'react';

import Button from 'components/common/button';
import { Flow } from 'components/common/flow';
import { Aftercare, LogoIcon } from 'components/common/icons';
import Image from 'components/common/image';
import { Sidenote } from 'components/common/item-card/styles';
import { Heading2, Heading3 } from 'components/common/text';
import { urlFor } from 'framework/sanity/url-for';
import { largeDesktop, mobile, tablet } from 'utils/media';

import * as St from './styles';
import { IListingGridListingProps } from './types';

export const ListingGridListing: FC<IListingGridListingProps> = ({ item }) => {
  const overlayIcon = item.overlay?.icon
    ? urlFor(item.overlay?.icon).width(400).auto('format').url()
    : '';

  const hasSecondaryLink = !!item.secondaryCtaLink && !!item.secondaryCtaText;
  const showCareIcon = item.secondaryCtaText === 'Care';

  return (
    <St.Item
      width={item.desktopWidth}
      textColor={item.textColor}
      backgroundColor={item.backgroundColor}
    >
      {item.image && (
        <Image
          src={item.image}
          aspectRatio={1.2}
          alt=""
          sizes={`${mobile} 100vw, ${tablet} 50vw, ${largeDesktop} ${
            (1440 / 6) * Number(item.desktopWidth || 2)
          }px`}
          loading="eager"
        />
      )}
      {overlayIcon && (
        <St.ItemOverlay maxWidth={item?.overlay?.maxWidth || 100}>
          <img src={overlayIcon} alt="" />
        </St.ItemOverlay>
      )}
      <Flow size="xs-s">
        {!item.path && item.ctaText && (
          <St.Tag>
            <LogoIcon color="currentColor" />
            <span>{item.ctaText}</span>
          </St.Tag>
        )}
        {item.title && <Heading2>{item.title}</Heading2>}
        {item.subtitle && <Heading3>{item.subtitle}</Heading3>}
        {item.path && item.ctaText && (
          <St.Footer as="footer">
            <Link passHref href={item.path}>
              <Button
                as="a"
                variant={hasSecondaryLink ? 'primary' : 'secondary'}
                colorScheme={item.textColor || 'white'}
              >
                {item.ctaText}
              </Button>
            </Link>
            {hasSecondaryLink && (
              <Link passHref href={item?.secondaryCtaLink || ''}>
                <Button
                  as="a"
                  variant="primary"
                  colorScheme={item.secondaryCtaColorScheme || 'white'}
                  hasIcon={showCareIcon}
                >
                  {showCareIcon && <Aftercare />}
                  <span>{item.secondaryCtaText}</span>
                </Button>
              </Link>
            )}
            {item.sidenote && <Sidenote>{item.sidenote}</Sidenote>}
          </St.Footer>
        )}
      </Flow>
    </St.Item>
  );
};
