import styled from 'styled-components';

import { CarouselItem, CarouselRow } from 'components/common/carousel';
import { ArrowButton } from 'components/common/carousel/styles';
import SiteLink from 'components/common/site-link';
import { Heading4 } from 'components/common/text';
import { desktop, largeDesktop, tablet } from 'utils/media';
import theme from 'utils/theme';

import { TWithColorScheme } from './types';

export const CarouselItemStyled = styled(CarouselItem)`
  min-width: 240px;
  width: 240px;

  ${tablet} {
    min-width: 210px;
    width: 210px;
  }

  ${desktop} {
    min-width: 290px;
    width: 290px;
  }

  ${largeDesktop} {
    min-width: 390px;
    width: 390px;
  }
`;

export const CarouselRowStyled = styled(CarouselRow)`
  ${ArrowButton} {
    ${tablet} {
      top: ${280 / 2 + 'px'};
    }

    ${desktop} {
      top: ${367 / 2 + 'px'};
    }

    ${largeDesktop} {
      top: ${520 / 2 + 'px'};
    }
  }
`;

export const SiteLinkStyled = styled(SiteLink)`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  height: 100%;
`;

export const TileBody = styled.div<TWithColorScheme>`
  width: 100%;
  color: ${({ colorScheme }) =>
    colorScheme in theme.colors ? theme.colors[colorScheme] : 'inherit'};
  margin-bottom: var(--space-xs);

  p {
    font-size: var(--step-1);

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const TileTitle = styled(Heading4)<TWithColorScheme>`
  margin-top: var(--space-l);
  margin-bottom: 0;
  color: ${({ colorScheme }) =>
    colorScheme in theme.colors ? theme.colors[colorScheme] : 'inherit'};

  + * {
    margin-top: var(--space-3xs);
  }
`;
