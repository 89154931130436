import styled from 'styled-components';

import { ButtonEl } from 'components/common/button/styles';
import { Flow, FlowX } from 'components/common/flow';
import { Sidenote } from 'components/common/item-card/styles';
import { Heading2, Heading3 } from 'components/common/text';
import { largeDesktop } from 'utils/media';
import theme from 'utils/theme';

interface Props {
  width?: string;
  textColor?: Sproutl.ColorScheme;
  backgroundColor?: Sproutl.ColorScheme;
}

export const Item = styled.div<Props>`
  --listing-grid: span ${({ width = '2' }) => width};
  color: ${({ textColor = 'white' }) => theme.colors[textColor]};
  background-color: ${({ backgroundColor = 'thistleBlue' }) =>
    theme.colors[backgroundColor]};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: min(100vw, 500px);

  ${largeDesktop} {
    min-height: 500px;
  }

  ${Heading2} {
    line-height: 1;
    font-size: var(--step-5);
  }

  ${Heading3} {
    --flow: var(--space-2xs);
    line-height: 1;
    font-size: var(--step-3);
  }

  ${ButtonEl}:first-child {
    position: static;

    :active {
      transform: none;
    }

    :after {
      content: '';
      position: absolute;
      z-index: ${theme.zIndexes.mid};
      inset: 0;
    }

    ~ ${ButtonEl} {
      position: relative;
      z-index: ${theme.zIndexes.mid};
    }
  }

  ${Flow} {
    ${({ backgroundColor }) =>
      backgroundColor &&
      `background: linear-gradient(
      180deg,
      ${theme.colors[backgroundColor]}00 30%,
      ${theme.colors[backgroundColor]}77 100%
    );`}
    position: relative;
    display: flex;
    padding: var(--site-gutter);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 1;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.heading};
  font-size: var(--step-1);

  svg {
    margin-right: var(--space-2xs);
    height: 1.25em;
    width: 1.25em;
  }

  span {
    display: block;
    height: 0.9em;
  }

  + * {
    --flow: var(--space-s-m);
  }
`;

export const Footer = styled(FlowX)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  ${Sidenote} {
    padding-left: var(--space-2xs);
    margin-left: auto;
  }
`;

interface IItemOverlayProps {
  maxWidth: number;
}

export const ItemOverlay = styled.figure<IItemOverlayProps>`
  position: absolute;
  padding: var(--site-gutter);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  img {
    max-width: ${({ maxWidth }) => maxWidth}%;
  }
`;
