import styled from 'styled-components';

import { tablet } from 'utils/media';
import theme from 'utils/theme';

import ResponsiveBox from '../responsive-box';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  :not(:last-child) {
    padding-bottom: var(--space-xs-s);
    margin-bottom: var(--space-xs-s);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const ItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.black};
  transition: ${theme.transitionSpeeds.fast}s opacity ease-out;

  &:focus,
  &:hover {
    opacity: 0.9;
  }
`;

export const Category = styled.div`
  font-size: var(--step--1);
  color: ${theme.colors.pineGreen};
  font-family: ${theme.fonts.heading};
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-size: var(--step--2);
  color: ${theme.colors.grey};
`;

export const ThumbnailWrapper = styled(ResponsiveBox)`
  border-radius: var(--space-l);
  margin-left: var(--space-l);
  flex-shrink: 0;
  width: 108px;
  height: 108px;
  ${tablet} {
    width: 196px;
    height: 135px;
  }
`;
