import { FC } from 'react';

import ArticleGrid from '../article-grid';
import Billboard from '../billboard';
import ContactForm from '../contact-form';
import { IContactFormProps } from '../contact-form/types';
import ContentCarousel from '../content-carousel';
import CopyTree from '../copy-tree';
import { EmbedBlock } from '../embed';
import { FAQs } from '../faqs';
import { IFAQsProps } from '../faqs/types';
import { HeroTwo } from '../hero-two';
import { IHeroTwoProps } from '../hero-two/types';
import { KitBlock } from '../kit';
import LatestArticles from '../latest-articles';
import { ILatestArticlesProps } from '../latest-articles/types';
import { ListingGrid } from '../listing-grid';
import { IListingGridProps } from '../listing-grid/types';
import PillList from '../pill-list';
import { IPillListProps } from '../pill-list/types';
import ShortcutGrid from '../shortcut-grid';
import SocialBlock from '../social-block';
import SocialCarousel from '../social-carousel';
import { SocialCarouselProps } from '../social-carousel/types';
import Text from '../text';
import TwoColumnText from '../two-column-text';

import {
  IMappedBlockProps,
  ISanityBillboard,
  ISanityContentCarousel,
  ISanityCopyTree,
  ISanityEmbed,
  ISanityKit,
  ISanityShortcutGrid,
  ISanitySocialBlock,
  ISanityTextBlock,
  ISanityTwoColumnTextBlock,
  TSanityBlock,
} from './types';

const MappedBlock: FC<IMappedBlockProps> = ({ block, index }) => {
  const { _type, _key, ...blockProps } = block;

  switch (_type) {
    case 'billboard':
      return (
        <Billboard
          {...(blockProps as ISanityBillboard)}
          index={index}
          key={_key}
        />
      );
    case 'articleGrid':
      return <ArticleGrid {...blockProps} key={_key} />;
    case 'shortcutGrid':
      return (
        <ShortcutGrid {...(blockProps as ISanityShortcutGrid)} key={_key} />
      );
    case 'productCarousel':
      return null;
    case 'contentCarousel':
      return (
        <ContentCarousel
          {...(blockProps as ISanityContentCarousel)}
          key={_key}
        />
      );
    case 'textBlock':
      return <Text {...(blockProps as ISanityTextBlock)} key={_key} />;
    case 'twoColumnTextBlock':
      return (
        <TwoColumnText
          {...(blockProps as ISanityTwoColumnTextBlock)}
          key={_key}
        />
      );
    case 'copyTree':
      return <CopyTree {...(blockProps as ISanityCopyTree)} key={_key} />;
    case 'socialBlock':
      return <SocialBlock {...(blockProps as ISanitySocialBlock)} key={_key} />;
    case 'socialCarousel':
      return (
        <SocialCarousel {...(blockProps as SocialCarouselProps)} key={_key} />
      );
    case 'editSet':
      return null;
    case 'emailSubscribeForm':
      return null;
    case 'contactForm':
      return <ContactForm {...(blockProps as IContactFormProps)} key={_key} />;
    case 'latestArticles':
      return (
        <LatestArticles {...(blockProps as ILatestArticlesProps)} key={_key} />
      );
    case 'listingGrid':
      return <ListingGrid {...(blockProps as IListingGridProps)} key={_key} />;
    case 'pillList':
      return <PillList {...(blockProps as IPillListProps)} key={_key} />;
    case 'heroTwo':
      return (
        <HeroTwo {...(blockProps as IHeroTwoProps)} index={index} key={_key} />
      );
    case 'faqsBlock':
      return <FAQs {...(blockProps as IFAQsProps)} key={_key} />;
    case 'kitBlock':
      return <KitBlock {...(blockProps as ISanityKit)} key={_key} />;
    case 'filterableGrid':
      return null;
    case 'embedBlock':
      return <EmbedBlock {...(blockProps as ISanityEmbed)} key={_key} />;
    default:
  }

  return null;
};

interface RenderBlocksProps {
  blocks: TSanityBlock[];
  extendedWidth?: boolean;
}

export default function RenderBlocks({
  blocks,
  extendedWidth,
}: RenderBlocksProps) {
  return (
    <>
      {blocks.map((block, index) =>
        MappedBlock({ block, extendedWidth, index }),
      )}
    </>
  );
}
