import { FC } from 'react';

import Container from 'components/common/container';
import Section from 'components/common/section';

import { ListingGridListing } from './listing';
import { ListingGridPanel } from './panel';
import * as St from './styles';
import { IListingGridProps } from './types';

export const ListingGrid: FC<IListingGridProps> = ({ items }) => {
  return (
    <Section bg="light">
      <Container>
        <St.Grid>
          {items.map((item) => {
            switch (item._type) {
              case 'listing':
                return <ListingGridListing item={item} key={item._key} />;
              case 'panel':
                return <ListingGridPanel item={item} key={item._key} />;
              default:
                return null;
            }
          })}
        </St.Grid>
      </Container>
    </Section>
  );
};
