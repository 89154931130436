import styled from 'styled-components';

export const TickListItem = styled.li`
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  text-align: left;
`;

export const TickOutline = styled.span`
  height: 1em;
  width: 1em;
  border: 1px solid;
  border-radius: 100%;
  position: relative;
  flex-shrink: 0;
  margin-right: 0.5em;

  svg {
    width: 65%;
    height: 65%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
