import * as Sentry from '@sentry/nextjs';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { CarouselItem, CarouselRow } from 'components/common/carousel';
import Container from 'components/common/container';
import { PortableText } from 'framework/sanity/portable-text';

import {
  TitleRow,
  SocialSection,
  SocialHeading2,
  CarouselContainer,
  InstagramPost,
  SkeletonBox,
} from './styles';
import { IInstagramPost, SocialBlockProps } from './types';

export default function SocialBlock({
  title,
  description,
  label,
  colorScheme,
}: SocialBlockProps) {
  const [posts, setPosts] = useState<IInstagramPost[]>([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!inView || hasLoaded) return;
    setHasLoaded(true);

    async function fetchSocialBlocks() {
      try {
        const posts = (await fetch('/api/curator/feed').then((response) =>
          response.json(),
        )) as IInstagramPost[];

        setPosts(posts);
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    fetchSocialBlocks();
  }, [inView, hasLoaded]);

  return (
    <SocialSection hexCode={label?.hexCode}>
      <Container noPadding noGutter ref={ref}>
        {title || description ? (
          <TitleRow>
            {title ? (
              <SocialHeading2 textColor={colorScheme?.text} inline>
                {title}
              </SocialHeading2>
            ) : null}
            <PortableText blocks={description} />
          </TitleRow>
        ) : null}
        <CarouselContainer>
          <CarouselRow arrowLayout="overlay">
            {posts.length
              ? posts.map((post) => {
                  return (
                    <CarouselItem key={post.id} size="xlarge">
                      <InstagramPost aspectRatio={1}>
                        <a href={post.url} target="_blank" rel="noreferrer">
                          <img src={post.image} alt={post.text} />
                        </a>
                      </InstagramPost>
                    </CarouselItem>
                  );
                })
              : [0, 1, 2, 3, 4, 5].map((index) => (
                  <CarouselItem key={index} size="xlarge">
                    <SkeletonBox aspectRatio={1} skeleton />
                  </CarouselItem>
                ))}
          </CarouselRow>
        </CarouselContainer>
      </Container>
    </SocialSection>
  );
}
