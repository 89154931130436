import React from 'react';

import Container from 'components/common/container';
import ItemsList from 'components/common/items-list';
import Section from 'components/common/section';
import { Heading3 } from 'components/common/text';
import { useArticleCta } from 'utils/articles';

import { ListingGridListing } from '../listing-grid/listing';

import * as St from './styles';
import { ILatestArticlesProps } from './types';

const LatestArticles = ({
  featuredArticle,
  latestArticles = [],
  section,
}: ILatestArticlesProps) => {
  const { watchCta, readCta } = useArticleCta();
  const { title, image, slug, _id: featuredArticleId } = featuredArticle || {};

  const transformedLatestArticles = latestArticles
    .filter((article) => article._id !== featuredArticleId)
    .slice(0, 3)
    .map((article) => {
      const { slug, title, category, image } = article;

      return {
        href: `/articles/${slug.current}`,
        title,
        category: category?.name,
        thumbnail: image,
        description: article.readingTime,
      };
    });

  const hasFeaturedArticle = !!featuredArticle;

  return (
    <Section id={section?.id}>
      <Container outset={hasFeaturedArticle}>
        <St.Container hasFeaturedArticle={hasFeaturedArticle}>
          {hasFeaturedArticle && title && (
            <ListingGridListing
              item={{
                backgroundColor: 'black',
                _type: 'listing',
                path: `/articles/${slug?.current}`,
                ctaText: featuredArticle.isVideo ? watchCta : readCta,
                sidenote: featuredArticle.readingTime || '',
                _key: title,
                title,
                image,
              }}
            />
          )}
          <div>
            <Heading3>Latest posts</Heading3>
            <ItemsList items={transformedLatestArticles} />
          </div>
        </St.Container>
      </Container>
    </Section>
  );
};

export default LatestArticles;
