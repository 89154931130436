import { FC } from 'react';

import Accordion from 'components/common/accordion';
import BodyTextContainer from 'components/common/body-text-container';
import Section from 'components/common/section';
import StructuredData from 'components/common/structured-data';
import { Heading3 } from 'components/common/text';
import { PortableText } from 'framework/sanity/portable-text';
import { toPlainText } from 'framework/sanity/to-plain-text';

import { IFAQsProps } from './types';

export const FAQs: FC<IFAQsProps> = ({
  questions,
  section,
  title,
  initiallyClosed,
}) => {
  return (
    <Section styles={section}>
      <BodyTextContainer>
        {title && <Heading3 as="h2">{title}</Heading3>}
        {questions.map((question) => (
          <Accordion
            key={question._key}
            title={question.question}
            initiallyOpen={!initiallyClosed}
          >
            <PortableText blocks={question.answer} />
          </Accordion>
        ))}
      </BodyTextContainer>

      <StructuredData
        data={{
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: questions.map((question) => ({
            '@type': 'Question',
            name: question.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: toPlainText(question.answer),
            },
          })),
        }}
      />
    </Section>
  );
};
