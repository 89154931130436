import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import { throwServerSideError, truncate } from 'utils/helpers';

const ZENDESK_ORDER_FIELD_ID = 360026552617;

interface ICreateZendeskRequestProps {
  name: string;
  email: string;
  message: string;
  category: string;
  phoneNumber: string;
  orderNumber?: number;
}

interface ICreateZendeskRequest {
  request: {
    comment: {
      body: string;
    };
    requester: {
      email: string;
      name?: string;
    };
    custom_fields?: { id: number; value: string | number }[];
    subject: string;
  };
}

interface ICreateZendeskRequestResponse {}

/**
 * Create a ticket using Zendesk's Request API
 * https://developer.zendesk.com/api-reference/ticketing/tickets/ticket-requests/#create-request
 * @async
 */
export default async function createZendeskRequest({
  name,
  email,
  message,
  orderNumber,
  category,
  phoneNumber,
}: ICreateZendeskRequestProps): Promise<ICreateZendeskRequestResponse> {
  throwServerSideError();

  try {
    const customFields = !!orderNumber
      ? [{ id: ZENDESK_ORDER_FIELD_ID, value: orderNumber }]
      : undefined;

    const data: ICreateZendeskRequest = {
      request: {
        comment: {
          body: `${message}\n\nTag: ${category}${
            phoneNumber ? `\nPhone number: ${phoneNumber}` : ''
          }`,
        },
        requester: {
          email,
          name,
        },
        subject: truncate(message, 70),
        custom_fields: customFields,
      },
    };

    const result = await axios.post<ICreateZendeskRequestResponse>(
      'https://sproutlhelp.zendesk.com/api/v2/requests',
      data,
    );
    return result.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Failed to create ticket');
  }
}
