import styled from 'styled-components';

import { Heading3 } from 'components/common/text';
import theme from 'utils/theme';

interface Props {
  width?: string;
  textColor?: Sproutl.ColorScheme;
  borderColor?: Sproutl.ColorScheme;
  backgroundColor?: Sproutl.ColorScheme;
}

export const Item = styled.div<Props>`
  --listing-grid: span ${({ width = '2' }) => width};
  color: ${({ textColor = 'irisPurple' }) => theme.colors[textColor]};
  border: 2px solid
    ${({ borderColor = 'irisPurple' }) => theme.colors[borderColor]};
  background-color: ${({ backgroundColor = 'white' }) =>
    theme.colors[backgroundColor]};
  border-radius: ${theme.radii.medium};
  position: relative;

  p {
    font-family: ${theme.fonts.heading};

    :first-child {
      font-size: var(--step-1);
    }
  }

  * {
    padding: 0;
    margin: 0;
  }

  > * {
    padding: var(--space-m) var(--space-s-xl);

    > * + * {
      margin-top: var(--space-s-l);
    }
  }

  ${Heading3} {
    margin: 0;
    font-size: var(--step-5);
    padding-top: var(--space-l-xl);
    letter-spacing: -0.02em;

    :not(:last-child) {
      border-bottom: 2px solid
        ${({ borderColor = 'irisPurple' }) => theme.colors[borderColor]};
    }
  }
`;
