import Link from 'next/link';
import React from 'react';

import Button from 'components/common/button';
import Section from 'components/common/section';

import * as St from './styles';
import { IPillListProps } from './types';

const PillList = ({ pills = [] }: IPillListProps) => (
  <Section padding="small">
    <St.Container>
      <St.Scrollable size="s">
        {pills.map(({ href, text }, index) => (
          <Link href={href} key={href + text + index} passHref>
            <Button as="a" variant="pill">
              {text}
            </Button>
          </Link>
        ))}
      </St.Scrollable>
    </St.Container>
  </Section>
);

export default PillList;
