import styled, { css } from 'styled-components';

import Container from '../container';

interface IBodyTextContainerProps {
  theme?: '' | 'article';
}

const BodyTextContainer = styled(Container)<IBodyTextContainerProps>`
  width: 800px;

  p {
    margin: 0;
  }

  > :first-child {
    > * + * {
      margin-top: var(--prose-flow, var(--space-s));
    }
  }

  ${({ theme }) =>
    theme === 'article' &&
    css`
      font-size: var(--step-1);

      p {
        letter-spacing: 0;
      }

      h2 {
        font-size: var(--step-5);

        :not(:first-child) {
          margin-top: var(--space-xl-2xl);
        }
      }

      h3 {
        font-size: var(--step-3);
      }

      h4 {
        font-size: var(--step-2);
      }

      h2,
      h3,
      h4 {
        --prose-flow: var(--space-m-xl);
        margin-bottom: 0;

        + p,
        + h3,
        + h4 {
          --prose-flow: var(--space-3xs);
        }
      }

      ul,
      ol {
        line-height: 1.5;
      }
    `}
`;

export default BodyTextContainer;
