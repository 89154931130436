import styled, { css } from 'styled-components';

import { desktop } from 'utils/media';

export const Container = styled.div<{ hasFeaturedArticle: Boolean }>`
  ${(props) =>
    props.hasFeaturedArticle &&
    css`
      display: grid;
      grid-gap: var(--space-2xl);
      ${desktop} {
        grid-template-columns: repeat(2, 1fr);
      }
    `}
`;
