import { FC } from 'react';

import { DarkeningOverlay } from 'components/common/darkening-overlay';
import Image from 'components/common/image';
import BackgroundVideo from 'components/common/video/background-video';
import { desktop, mobile, tablet } from 'utils/media';

import { IHeroTwoLayer } from '../types';

export const HeroTwoPoster: FC<{
  poster?: IHeroTwoLayer[];
}> = ({ poster = [] }) => {
  return (
    <>
      {poster.map((layer) => {
        return layer._type === 'image' ? (
          <Image
            key={layer._key}
            alt=""
            src={layer.asset}
            aspectRatio={
              layer.asset.metadata.dimensions.height /
              layer.asset.metadata.dimensions.width
            }
            sizes="100vw"
          />
        ) : layer._type === 'video' ? (
          <BackgroundVideo
            key={layer._key}
            video={layer}
            breakpoints={{
              [mobile]: { width: 960, height: 540 },
              [tablet]: { width: 1280, height: 720 },
              [desktop]: { width: 1920, height: 1080 },
            }}
          />
        ) : layer._type === 'shim' ? (
          <DarkeningOverlay key={layer._key} {...layer} />
        ) : null;
      })}
    </>
  );
};
