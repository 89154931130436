import { FC } from 'react';

import { Heading3 } from 'components/common/text';
import { PortableText } from 'framework/sanity/portable-text';

import * as St from './styles';
import { IListingGridPanelProps } from './types';

export const ListingGridPanel: FC<IListingGridPanelProps> = ({ item }) => {
  return (
    <St.Item
      width={item.desktopWidth}
      textColor={item.textColor}
      backgroundColor={item.backgroundColor}
      borderColor={item.borderColor}
    >
      <Heading3>{item.title}</Heading3>
      {item.body?.length && (
        <PortableText renderContainerOnSingleChild blocks={item.body} />
      )}
    </St.Item>
  );
};
