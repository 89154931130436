import { FC } from 'react';

import * as St from './styles';
import { TickListItem } from './tick-list-item';
import { ITickListProps } from './types';

export const TickList: FC<ITickListProps> = ({ labels }) => {
  if (!labels?.length) return null;

  return (
    <St.TickListEl>
      {labels.map((label) => (
        <TickListItem label={label} key={label} />
      ))}
    </St.TickListEl>
  );
};
