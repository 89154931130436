import styled from 'styled-components';

import { desktop, tablet } from 'utils/media';

export const Grid = styled.div`
  display: grid;
  grid-gap: var(--site-gutter);

  ${tablet} {
    grid-template-columns: repeat(6, 1fr);

    > * {
      grid-column: span 3;
    }
  }

  ${desktop} {
    > * {
      grid-column: var(--listing-grid, 2);
    }
  }
`;
