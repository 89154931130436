import styled from 'styled-components';

export const TickListEl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  gap: var(--space-xs) var(--space-l);
  width: 100%;
  display: flex;
  flex-direction: column;
`;
