import Link from 'next/link';
import React from 'react';

import { Flow } from 'components/common/flow';
import Image from 'components/common/image';
import { Heading5 } from 'components/common/text';
import { mobile } from 'utils/media';

import * as St from './styles';
import { IProps } from './types';

const ItemsList = ({ items = [] }: IProps) => {
  return (
    <St.List>
      {items.map(({ title, href, description, category, thumbnail }) => (
        <St.ListItem key={title}>
          <Link href={href} passHref>
            <St.ItemLink>
              <Flow size="2xs">
                {category && <St.Category>{category}</St.Category>}
                <Heading5 as="h4">{title}</Heading5>
                {description && <St.Description>{description}</St.Description>}
              </Flow>

              {!!thumbnail && (
                <St.ThumbnailWrapper aspectRatio={1} rounded>
                  <Image
                    src={thumbnail}
                    alt=""
                    sizes={`${mobile} 108px, 193px`}
                    aspectRatio={1}
                  />
                </St.ThumbnailWrapper>
              )}
            </St.ItemLink>
          </Link>
        </St.ListItem>
      ))}
    </St.List>
  );
};

export default ItemsList;
