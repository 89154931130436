import styled, { css } from 'styled-components';

import { CarouselItem } from 'components/common/carousel';
import ResponsiveBox from 'components/common/responsive-box';
import { desktop, largeDesktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const StyledResponsiveBox = styled(ResponsiveBox)`
  background-olor: ${theme.colors.offWhite};
  z-index: ${theme.zIndexes.normal};
  border-radius: ${theme.radii.default};

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const Label = styled.div`
  font-family: ${theme.fonts.heading};
  margin-top: var(--space-s);
  font-size: var(--step-2);

  ${tablet} {
    /* Drop font size when there are 6+ items */
    ${CarouselItem}:first-child:nth-last-child(n + 6) * &,
    ${CarouselItem}:first-child:nth-last-child(n + 6) ~ * & {
      font-size: var(--step-1);
    }
  }
`;

export const MaskedIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${theme.zIndexes.low};
  color: ${theme.colors.white};
  background-color: ${theme.colors.iconUnderlay};
`;

export const StyledLink = styled.a`
  color: ${theme.colors.black};
  text-align: center;

  &:hover,
  &:focus {
    color: ${theme.colors.purple};
  }
`;

export const ShortcutViewAll = styled.button`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: ${theme.zIndexes.low};
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) min(30vw, 150px),
    rgba(255, 255, 255, 1)
  );
  display: none;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  padding: min(30vw, 150px) var(--space-s) 0;
  color: ${theme.colors.black};
  text-decoration: underline;
`;

interface IShortcutGridLayoutProps {
  isViewingAll: boolean;
}

export const ShortcutGridLayout = styled.div<IShortcutGridLayoutProps>`
  display: grid;
  grid-gap: var(--site-gutter);
  grid-template-columns: repeat(var(--items-per-row, 2), 1fr);
  position: relative;

  ${Label} {
    font-size: var(--step-1);
  }

  ${tablet} {
    --items-per-row: 3;
  }

  ${desktop} {
    --items-per-row: 4;
  }

  ${largeDesktop} {
    --items-per-row: 5;
  }

  ${({ isViewingAll }) =>
    !isViewingAll &&
    css`
      max-height: 130vw;
      overflow: hidden;

      > :first-child:nth-last-child(n + 8) ~ ${ShortcutViewAll} {
        display: flex;
      }

      ${tablet} {
        max-height: 80vw;
      }

      ${desktop} {
        max-height: 60vw;

        /* Reset previous selector */
        > :first-child:nth-last-child(n + 8) ~ ${ShortcutViewAll} {
          display: none;
        }

        > :first-child:nth-last-child(n + 10) ~ ${ShortcutViewAll} {
          display: flex;
        }
      }

      ${largeDesktop} {
        max-height: 650px;

        /* Reset previous selector */
        > :first-child:nth-last-child(n + 10) ~ ${ShortcutViewAll} {
          display: none;
        }

        > :first-child:nth-last-child(n + 12) ~ ${ShortcutViewAll} {
          display: flex;
        }
      }
    `}
`;
