import styled from 'styled-components';

import { InputEl } from 'components/common/forms/input/styles';
import { SelectEl } from 'components/common/forms/select/styles';
import { TextareaEl } from 'components/common/forms/textarea/styles';
import theme from 'utils/theme';

export const LabelEl = styled.label`
  color: ${theme.colors.black};
  font-size: var(--step--1);
  margin-bottom: var(--space-s);
  display: block;

  ${InputEl},
  ${SelectEl},
  ${TextareaEl} {
    margin-top: var(--space-3xs);
  }
`;
