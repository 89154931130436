import styled from 'styled-components';

import Container from 'components/common/container';
import { tablet } from 'utils/media';

export const TwoColumnContainer = styled(Container)`
  display: grid;
  grid-gap: var(--site-gutter);
  grid-template-columns: 1fr;

  > * {
    width: 100%;
  }

  ${tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;
