import { IDarkeningOverlayProps } from 'components/common/darkening-overlay/types';
import { SanityImageAsset } from 'utils/types';

export enum EHeroTwoBreakpoint {
  mobileUp = 'mobileUp',
  tabletUp = 'tabletUp',
  desktopUp = 'desktopUp',
}

export enum EHeroTwoHorizontalAlignment {
  overlay = 'overlay',
  beforeImageHalf = 'beforeImageHalf',
  beforeImageThird = 'beforeImageThird',
  afterImageHalf = 'afterImageHalf',
  afterImageThird = 'afterImageThird',
}

export enum EHeroTwoVerticalAlignment {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}

export interface IHeroTwoImageLayer extends Sanity.JoinedImageAsset {
  _key: string;
}

export interface IHeroTwoCloudinaryVideoLayer
  extends Omit<Sanity.CloudinaryVideo, '_type'> {
  _key: string;
  _type: 'video';
}

export interface IHeroTwoDarkeningLayer extends IDarkeningOverlayProps {
  _key: string;
  _type: 'shim';
}

export type IHeroTwoLayer =
  | IHeroTwoImageLayer
  | IHeroTwoCloudinaryVideoLayer
  | IHeroTwoDarkeningLayer;

export type THeroContainer = 'normal' | 'inset' | 'fullWidth';

export interface IHeroTwoMediaAtBreakpoint {
  _key: string;
  _type: 'mediaAtBreakpoint';
  breakpoint: EHeroTwoBreakpoint;
  horizontalAlignment: EHeroTwoHorizontalAlignment;
  verticalAlignment: EHeroTwoVerticalAlignment;
  minAspectRatio: 'content' | string;
  layers: IHeroTwoLayer[];
  poster?: IHeroTwoLayer[];
  controls?: boolean;
}

export interface IHeroTwoProps {
  index?: number;
  title?: string;
  titleSize?: string;
  titleElement?: React.ElementType;
  body?: string;
  wysiwyg?: Sanity.TPortableTextEntry[];
  heroTwoMedia?: IHeroTwoMediaAtBreakpoint[] | null;
  theme?: string;
  ticklist?: string[];
  footer?: Sanity.TPortableTextEntry[] | null;
  cta?: {
    title?: string;
    url?: string;
    buttonVariant?: 'primary' | 'secondary';
  };
  playButton?: {
    text?: string;
    buttonVariant: 'primary' | 'secondary';
  };
  eyebrow?: {
    copy?: string;
    icon?: {
      icon: SanityImageAsset;
    };
  };
  primaryLogo?: Sanity.JoinedImageAsset;
  secondaryLogo?: Sanity.JoinedImageAsset;
  container: THeroContainer;
  trackingPrefix: string;
  onCtaClick?: () => void;
}

export interface IHeroTwoMediaAtSproutlBreakpoint
  extends IHeroTwoMediaAtBreakpoint {
  query: string;
  ratio: string;
}
