import Head from 'next/head';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { ResponsiveVideoFrame, ResponsiveVideoElement } from '../style';
import { VideoProps, VideoSource } from '../types';
import { pickSource } from '../utils';

export default function BackgroundVideo({
  video,
  breakpoints,
  preloadPoster = false,
  muted = true,
  autoplay = true,
  loop = true,
  controls = false,
  objectFit = 'cover',
  videoRef,
  onPause,
  onEnded,
}: VideoProps) {
  const urlEncodedId = encodeURI(video.public_id);

  const breakpointKeys = Object.keys(breakpoints || {}) || ['desktop'];
  const lastBreakpoint = breakpoints[
    breakpointKeys[breakpointKeys.length - 1]
  ] || { desktop: { height: 540, width: 960 } };
  const posterSrc = `https://res.cloudinary.com/sproutl/video/upload/c_fill,h_${lastBreakpoint.height},so_0,w_${lastBreakpoint.width}/v1/${urlEncodedId}.webp`;

  const sources = useMemo(
    () =>
      Object.entries(breakpoints).map(([media, dimensions]) => ({
        formats: [
          {
            src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_webm,w_${
              dimensions.width
            },h_${dimensions.height},c_fill/${
              dimensions.video?.public_id || urlEncodedId
            }.webm`,
            type: 'video/webm',
          },
          {
            src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_mp4,vc_h265,w_${
              dimensions.width
            },h_${dimensions.height},c_fill/${
              dimensions.video?.public_id || urlEncodedId
            }.mp4`,
            type: 'video/mp4; codecs=hvc1',
          },
          {
            src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_mp4,vc_h264,w_${
              dimensions.width
            },h_${dimensions.height},c_fill/${
              dimensions.video?.public_id || urlEncodedId
            }.mp4`,
            type: 'video/mp4',
          },
        ],
        media: media.replace('@media', ''),
      })),
    [breakpoints, urlEncodedId],
  );

  const [currentSource, setCurrentSource] = useState<VideoSource | null>(null);

  const fallbackVideoRef = useRef<HTMLVideoElement>(null);
  const internalVideoRef = videoRef || fallbackVideoRef;
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const newSource = pickSource(sources);

    if (newSource) {
      setCurrentSource(newSource);
    }
  }, [sources]);

  const internalOnPause = () => {
    if (
      onPause &&
      internalVideoRef.current &&
      !internalVideoRef.current.seeking
    ) {
      onPause(Math.round(internalVideoRef.current.currentTime));
    }
  };

  const poster = preloadPoster ? posterSrc : inView ? posterSrc : undefined;

  return (
    <>
      {preloadPoster ? (
        <Head>
          <link rel="preload" href={posterSrc} as="image" />
        </Head>
      ) : null}
      <ResponsiveVideoFrame ref={ref}>
        {/* Presentational 'poster' to sit behind the video and stop the Safari flicker when the video loads */}
        {poster && <img src={poster} alt="" />}
        <ResponsiveVideoElement
          autoPlay={autoplay}
          controls={controls}
          muted={muted}
          loop={loop}
          playsInline
          poster={poster}
          ref={internalVideoRef}
          objectFit={objectFit}
          onEnded={onEnded}
          onPause={internalOnPause}
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
        >
          {(inView || preloadPoster) && currentSource
            ? currentSource.formats.map((format) => (
                <source key={format.src} src={format.src} type={format.type} />
              ))
            : null}
        </ResponsiveVideoElement>
      </ResponsiveVideoFrame>
    </>
  );
}
