import { FC, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useDelayedRender from 'use-delayed-render';

import Section from 'components/common/section';
import { GlobalEvents, trigger } from 'utils/global-events';
import {
  trackHeroCta,
  trackHeroVideoClose,
  trackHeroVideoEnded,
  trackHeroVideoPaused,
  trackHeroVideoPlay,
} from 'utils/gtm/events';
import theme from 'utils/theme';

import { HeroTwoContent } from './content';
import { HeroTwoMedia } from './media';
import { HeroTwoPoster } from './poster';
import * as St from './styles';
import { IHeroTwoProps } from './types';
import { prepareHeroLayers } from './utils';

export const HeroTwo: FC<IHeroTwoProps> = ({
  title,
  titleSize,
  eyebrow,
  wysiwyg,
  body,
  cta,
  ticklist = [],
  footer,
  titleElement = 'h1',
  heroTwoMedia: media = [],
  theme: buttonTheme = 'transparent/white',
  playButton,
  primaryLogo,
  secondaryLogo,
  container,
  trackingPrefix,
  onCtaClick,
  index,
}) => {
  const hasPoster = !!media?.find(
    (mediaAtBreakpoint) => !!mediaAtBreakpoint.poster,
  );
  const [isTeaserVisible, setIsTeaserVisible] = useState(!!hasPoster);
  const [hasVideoPlayed, setHasVideoPlayed] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const { mounted: isTeaserMounted, rendered: isTeaserRendered } =
    useDelayedRender(isTeaserVisible, {
      exitDelay: theme.transitionSpeeds.slow * 1000,
    });

  const playFullVideo = () => {
    setIsTeaserVisible(false);
    setHasVideoPlayed(true);
    trackHeroVideoPlay(trackingPrefix);
  };

  const onEnded = () => {
    setIsTeaserVisible(hasPoster);
    trackHeroVideoEnded(trackingPrefix);
  };

  const onClose = () => {
    setIsTeaserVisible(hasPoster);
    trackHeroVideoClose(videoRef.current?.currentTime || 0, trackingPrefix);
  };

  const onPause = (timestamp: number) => {
    trackHeroVideoPaused(timestamp, trackingPrefix);
  };

  const [background = 'whiteTransparent', text = 'white'] =
    buttonTheme.split(':');

  const onSubcribeClick = () => {
    onCtaClick && onCtaClick();

    trackHeroCta(
      hasVideoPlayed ? 'CTA Click After Video Play' : 'CTA Click',
      trackingPrefix,
    );

    // Open the global email pop up
    trigger(GlobalEvents.openEmailPopup);
  };

  const isPlayingPosteredVideo = hasPoster && !isTeaserMounted;

  const mobileBreakpoint = media?.find(
    ({ breakpoint }) => 'mobileUp' === breakpoint,
  );
  const tabletBreakpoint =
    media?.find(({ breakpoint }) => 'tabletUp' === breakpoint) ||
    mobileBreakpoint;
  const desktopBreakpoint =
    media?.find(({ breakpoint }) => 'desktopUp' === breakpoint) ||
    tabletBreakpoint;

  if (!mobileBreakpoint || !tabletBreakpoint || !desktopBreakpoint) return null;

  const aspectRatios = prepareHeroLayers({
    mobileBreakpoint,
    tabletBreakpoint,
    desktopBreakpoint,
    isPlayingPosteredVideo,
  });

  return (
    <Section
      styles={{
        background: 'white',
      }}
    >
      <St.AspectWrapper
        {...{ ref, aspectRatios, isPlayingPosteredVideo, container }}
      >
        <St.Wrapper aspectRatios={aspectRatios} background={background}>
          <St.MediaWrapper>
            {aspectRatios.map(
              ({
                query,
                ratio,
                layers,
                poster,
                controls,
                horizontalAlignment,
              }) => {
                return (
                  <St.BreakpointWrapper query={query} ratio={ratio} key={query}>
                    <HeroTwoPoster poster={poster} />

                    {(!isTeaserMounted || !poster?.length) &&
                      (index === 0 || inView) && (
                        <HeroTwoMedia
                          hasPoster={!!poster?.length}
                          controls={!!controls}
                          colorScheme={text}
                          {...{
                            layers,
                            onClose,
                            onEnded,
                            onPause,
                            isTeaserRendered,
                            videoRef,
                            horizontalAlignment,
                            query,
                            ratio,
                          }}
                        />
                      )}
                  </St.BreakpointWrapper>
                );
              },
            )}
          </St.MediaWrapper>

          <St.ContentWrapper
            text={text}
            hideContent={!isTeaserRendered && hasPoster}
          >
            <HeroTwoContent
              colorScheme={text}
              {...{
                title,
                titleElement,
                titleSize,
                eyebrow,
                ticklist,
                footer,
                body,
                wysiwyg,
                playButton,
                cta,
                primaryLogo,
                secondaryLogo,
              }}
              onPlayClick={playFullVideo}
              onCtaClick={onCtaClick}
              onSubcribeClick={onSubcribeClick}
            />
          </St.ContentWrapper>
        </St.Wrapper>
      </St.AspectWrapper>
    </Section>
  );
};
