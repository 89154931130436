import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { FC, useEffect, useState } from 'react';

import { Kit } from 'components/kit/kit';
import multiindexSearch from 'framework/algolia/api/multiindex-search';
import { getKitProducts } from 'framework/algolia/api/multiindex-search/queries';
import { ICollectionOptimationResponse } from 'pages/collections/types';
import { IProductMeta, IProductMetaWithVariants } from 'types/product';

import { IKitBlockProps } from './types';

export const KitBlock: FC<IKitBlockProps> = (props) => {
  const [kitProducts, setKitProducts] = useState<IProductMeta[]>([]);
  const [kitError, setKitError] = useState(false);
  const { kit } = props;

  useEffect(() => {
    const fetchKit = async () => {
      try {
        if (!!kit) {
          const optimiseParams = new URLSearchParams();
          optimiseParams.append('slug', kit.pimId);
          optimiseParams.append(
            'groups',
            JSON.stringify(
              kit.groupIds.map((id) => ({
                id,
                minPerPartner: 1,
              })),
            ),
          );
          const optimiseUrl = `${
            process.env.NEXT_PUBLIC_COLLECTIONS_OPTIMISATION_URL
          }?${optimiseParams.toString()}`;

          let optimisationResponse: ICollectionOptimationResponse = await fetch(
            optimiseUrl,
            {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            },
          ).then((response) => response.json());

          if (optimisationResponse.ERROR) {
            throw new Error(optimisationResponse.ERROR);
          }

          const response = await multiindexSearch<IProductMetaWithVariants>(
            getKitProducts(
              kit.pimId,
              kit.groupIds,
              optimisationResponse.rankedPartners?.[0].partnerCode,
            ),
          );
          const products = response.flatMap(({ hits }) => hits);

          if (!products.length) {
            throw new Error(`No products for "${kit.pimId}" kit`);
          }

          setKitProducts(products);
        }
      } catch (error) {
        Sentry.captureException(error, { level: Severity.Error });
        setKitError(true);
      }
    };

    fetchKit();
  }, [kit]);

  if (kitError) return null;

  return (
    <Kit {...props} products={kitProducts} showSkeleton={!kitProducts.length} />
  );
};
