import styled, { keyframes } from 'styled-components';

import theme from 'utils/theme';

import { LoaderDiv } from '../loader/styles';

const LoaderFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
`;

interface ResponsiveVideoFrameProps {
  activeAndLoading?: boolean;
}

export const ResponsiveVideoFrame = styled.div<ResponsiveVideoFrameProps>`
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: -2px;
  overflow: hidden;
  font-size: 0;
  line-height: 0;

  > img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  ${LoaderDiv} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    filter: brightness(5);
    opacity: 0;
    max-width: 30%;
    animation: ${({ activeAndLoading }) =>
        activeAndLoading ? LoaderFadeIn : null}
      ${theme.transitionSpeeds.slow}s 1s forwards;
  }
`;

interface IVideoProps {
  objectFit?: string;
}

export const StaticVideoElement = styled.video<IVideoProps>``;

export const ResponsiveVideoElement = styled(StaticVideoElement)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;

  object-fit: ${({ objectFit = 'cover' }) => objectFit};
`;

export const MuteControl = styled.button`
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.7);
  border: 0;
  width: var(--space-2xl);
  height: var(--space-2xl);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white};
  z-index: 10;
  cursor: pointer;
  position: absolute;
  bottom: var(--space-m);
  left: var(--space-m);
  opacity: 0.75;
  transition: ${theme.transitionSpeeds.fast}s opacity;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colors.darkGreen};
  }

  &:hover {
    opacity: 1;
  }
`;

interface IPlayControlProps {
  isPlaying: boolean;
}

export const PlayControl = styled.button<IPlayControlProps>`
  position: absolute;
  transition: ${theme.transitionSpeeds.fast}s opacity;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: ${({ isPlaying }) => (isPlaying ? 0 : 1)};

  > * {
    border: var(--space-4xs-3xs) solid;
    width: var(--space-2xl-5xl);
    height: var(--space-2xl-5xl);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.black};
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: ${theme.transitionSpeeds.fast}s background;
    transform: translate(-50%, -50%) rotate(-90deg);

    svg {
      width: 80%;
      height: 80%;
      transform: translateY(5%); // Optical correction
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px ${theme.colors.darkGreen};
    }

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
`;
