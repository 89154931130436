import { useField } from 'formik';
import React from 'react';

import { Chevron } from 'components/common/icons';
import theme from 'utils/theme';

import { Wrapper, SelectEl, Label } from './styles';
import { SelectProps } from './types';

export default function Select(props: SelectProps) {
  const [field] = useField(props);
  const { placeholder: rawPlaceholder, children, bg, required } = props;

  const selectedOption = React.Children.toArray(children)
    .filter(
      (child) =>
        React.isValidElement(child) &&
        child.type === 'option' &&
        'props' in child,
    )
    .map((item) => (item as React.ReactElement).props)
    .find((props) => props.value === field.value)?.children;

  const placeholder =
    rawPlaceholder && required ? `${rawPlaceholder}*` : rawPlaceholder;

  return (
    <Wrapper>
      <SelectEl {...field} {...props}>
        {placeholder && (
          <option disabled value="">
            {rawPlaceholder}
          </option>
        )}
        {children}
      </SelectEl>
      <Label bg={bg} color={selectedOption ? theme.colors.black : undefined}>
        {selectedOption || placeholder}
        <Chevron />
      </Label>
    </Wrapper>
  );
}
