import Link from 'next/link';
import React, { useState } from 'react';

import { CarouselRow, CarouselItem } from 'components/common/carousel';
import Container from 'components/common/container';
import MaskedIcon from 'components/common/masked-icon';
import Section from 'components/common/section';
import { ICustomSectionSettings } from 'components/common/section/types';
import { HeadingWrapper } from 'components/common/text';
import { Heading2, HeadingLink } from 'components/common/text';
import { IHeadingLink } from 'components/common/text/heading-link/types';
import { urlFor } from 'framework/sanity/url-for';
import { desktop, mobile, tablet } from 'utils/media';
import { SanityImageAsset } from 'utils/types';

import {
  StyledLink,
  StyledResponsiveBox,
  MaskedIconWrapper,
  Label,
  ShortcutGridLayout,
  ShortcutViewAll,
} from './styles';

export interface ShortcutProps {
  _key: string;
  _type: 'shortcut';
  name?: string;
  path?: string;
  image?: string;
  icon?: SanityImageAsset;
}

export interface ShortcutGridProps {
  title?: string;
  titleLink?: IHeadingLink;
  shortcuts?: ShortcutProps[];
  layout?: 'carousel' | 'grid';
  hideScrollbarTillHover?: boolean;
  section?: ICustomSectionSettings;
}

export default function ShortcutGrid({
  title,
  shortcuts = [],
  titleLink = {},
  layout = 'carousel',
  hideScrollbarTillHover = false,
  section,
}: ShortcutGridProps) {
  const { text, url } = titleLink;
  const isCarousel = layout === 'carousel';
  const [isViewingAll, setIsViewingAll] = useState(false);
  const showAll = () => setIsViewingAll(true);

  const shortcutElements = shortcuts?.map((shortcut) => {
    const image = shortcut.image
      ? urlFor(shortcut.image)
          .width(400)
          .height(300)
          .quality(80)
          .auto('format')
          .url()
      : '';

    return {
      key: shortcut._key,
      element: (
        <Link key={shortcut._key} href={shortcut.path || '/'} passHref>
          <StyledLink>
            <StyledResponsiveBox aspectRatio={3 / 4}>
              {shortcut.icon && (
                <MaskedIconWrapper>
                  <MaskedIcon
                    asset={shortcut.icon}
                    size={{ [mobile]: 48, [tablet]: 48, [desktop]: 64 }}
                  />
                </MaskedIconWrapper>
              )}
              {image && (
                <img
                  src={image}
                  width="400"
                  height="300"
                  alt=""
                  loading="lazy"
                />
              )}
            </StyledResponsiveBox>
            <Label>{shortcut.name}</Label>
          </StyledLink>
        </Link>
      ),
    };
  });

  return (
    <>
      {!!shortcutElements && (
        <Section styles={section}>
          <Container noGutter={isCarousel} noPadding={isCarousel}>
            {!!title && (
              <HeadingWrapper noGutter={!isCarousel}>
                <Heading2 inline>{title}</Heading2>
                {text && url && (
                  <Link href={url} passHref>
                    <HeadingLink href={url}>{text}</HeadingLink>
                  </Link>
                )}
              </HeadingWrapper>
            )}

            {isCarousel ? (
              <CarouselRow
                removeScrollbarGap
                scrollBarReveal={hideScrollbarTillHover}
              >
                {shortcutElements.map(({ element, key }) => (
                  <CarouselItem size="shortcut" key={key}>
                    {element}
                  </CarouselItem>
                ))}
              </CarouselRow>
            ) : (
              <ShortcutGridLayout isViewingAll={isViewingAll}>
                {shortcutElements.map(({ element }) => element)}
                {!isViewingAll && (
                  <ShortcutViewAll
                    type="button"
                    aria-hidden="true"
                    onClick={showAll}
                  >
                    View More
                  </ShortcutViewAll>
                )}
              </ShortcutGridLayout>
            )}
          </Container>
        </Section>
      )}
    </>
  );
}
