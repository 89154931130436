import { useField } from 'formik';
import React, { TextareaHTMLAttributes } from 'react';

import { TextareaEl } from './styles';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  bg?: 'white' | 'lightestGrey';
}

export default function Textarea({ bg = 'white', ...props }: InputProps) {
  const [field, meta] = useField(props);

  return (
    <>
      <TextareaEl bg={bg} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
}
