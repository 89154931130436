import React from 'react';

import BodyTextContainer from 'components/common/body-text-container';
import Section, { SectionProps } from 'components/common/section';
import { PortableText } from 'framework/sanity/portable-text';

import { TextProps } from './types';

export default function Text({ body, bg, section, theme }: TextProps) {
  // TODO: Remove once cleared from Sanity
  const sectionProps: SectionProps = !!section
    ? { styles: section }
    : { padding: 'small', bg: bg || 'light' };

  return (
    <Section {...sectionProps}>
      <BodyTextContainer theme={theme}>
        <PortableText blocks={body} renderContainerOnSingleChild />
      </BodyTextContainer>
    </Section>
  );
}
