import styled from 'styled-components';

import theme from 'utils/theme';

import { IDarkeningOverlayProps } from './types';

export const Overlay = styled.div<IDarkeningOverlayProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  ${({ color = 'black', opacity, format, blendMode = 'normal' }) =>
    color &&
    `background: linear-gradient(
      ${format === 'gradientFromTop' ? 0 : 180}deg,
      ${theme.colors[color]}${format === 'block' ? `${opacity} 0%` : '00 40%'},
      ${theme.colors[color]}${opacity} 100%
    );
    mix-blend-mode: ${blendMode};`}
`;
