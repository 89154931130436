import { FC } from 'react';

import Section from 'components/common/section';
import { PortableText } from 'framework/sanity/portable-text';

import * as St from './styles';
import { IEmbedBlockProps } from './types';
import { YouTubeEmbed } from './youtube';

export const EmbedBlock: FC<IEmbedBlockProps> = ({
  source,
  embedUrl,
  section,
  body,
  aspectRatio,
  maxWidth,
  layout,
}) => {
  const Embed = () => {
    switch (source) {
      case 'YouTube':
        return <YouTubeEmbed embedUrl={embedUrl} />;
      default:
        return null;
    }
  };

  return (
    <Section styles={section}>
      <St.StyledContainer maxWidth={maxWidth}>
        <St.EmbedWrapper layout={layout}>
          <St.Embed aspectRatio={aspectRatio} maxWidth={maxWidth}>
            <Embed />
          </St.Embed>
        </St.EmbedWrapper>
        {!!body?.length && (
          <PortableText
            className="embed-content"
            blocks={body}
            renderContainerOnSingleChild
          />
        )}
      </St.StyledContainer>
    </Section>
  );
};
