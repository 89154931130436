import Link from 'next/link';
import { FC } from 'react';

import Button from 'components/common/button';
import { Play } from 'components/common/icons';
import { Heading1, Heading2 } from 'components/common/text';
import { TickList } from 'components/common/tick-list';
import { PortableText } from 'framework/sanity/portable-text';

import * as St from '../styles';
import { IHeroTwoProps } from '../types';
import { isSubscribeCTA } from '../utils';

interface IHeroTwoContentProps
  extends Omit<IHeroTwoProps, 'heroTwoMedia' | 'trackingPrefix' | 'container'> {
  colorScheme: string;
  onCtaClick?: () => void;
  onSubcribeClick?: () => void;
  onPlayClick?: () => void;
}

export const HeroTwoContent: FC<IHeroTwoContentProps> = ({
  title,
  titleElement,
  titleSize,
  body,
  wysiwyg,
  eyebrow,
  primaryLogo,
  secondaryLogo,
  colorScheme,
  cta,
  playButton,
  ticklist = [],
  footer,
  onPlayClick,
  onCtaClick,
  onSubcribeClick,
}) => {
  const Title = () => {
    if (!title) return null;

    return titleSize === 'h2' ? (
      <Heading2 as={titleElement} inline>
        {title}
      </Heading2>
    ) : (
      <Heading1 as={titleElement} inline>
        {title}
      </Heading1>
    );
  };

  const hasFooter = secondaryLogo || !!ticklist.length || footer?.length;
  const hasHeader = hasFooter;

  return (
    <>
      {hasHeader && <St.ContentTop></St.ContentTop>}

      <St.ContentCenter>
        {eyebrow?.copy && (
          <St.Eyebrow>
            {eyebrow.icon && <St.StyledMaskedIcon asset={eyebrow.icon.icon} />}
            <span>{eyebrow.copy}</span>
          </St.Eyebrow>
        )}

        <Title />

        {primaryLogo && <St.PrimaryLogo alt="" src={primaryLogo.asset.url} />}

        {!!wysiwyg?.length && (
          <St.Body>
            <PortableText blocks={wysiwyg} />
          </St.Body>
        )}

        {!!body && !wysiwyg?.length && (
          <St.Body>
            <p>{body}</p>
          </St.Body>
        )}

        {cta?.title && (
          <St.CtaWrapper size="xs">
            {cta?.url &&
              (!isSubscribeCTA(cta?.url) ? (
                <Link passHref href={cta.url}>
                  <Button
                    as="a"
                    variant={cta.buttonVariant}
                    colorScheme={colorScheme as Sproutl.ColorScheme}
                    onClick={onCtaClick}
                  >
                    {cta.title}
                  </Button>
                </Link>
              ) : (
                <Button
                  variant={cta.buttonVariant}
                  colorScheme={colorScheme as Sproutl.ColorScheme}
                  onClick={onSubcribeClick}
                >
                  {cta.title}
                </Button>
              ))}

            {playButton?.text && (
              <Button
                colorScheme={colorScheme as Sproutl.ColorScheme}
                variant={playButton.buttonVariant}
                onClick={onPlayClick}
              >
                <Play />
                <span>{playButton.text}</span>
              </Button>
            )}
          </St.CtaWrapper>
        )}
      </St.ContentCenter>

      {hasFooter && (
        <St.ContentBottom>
          {secondaryLogo && (
            <St.SecondaryLogo alt="" src={secondaryLogo.asset.url} />
          )}
          {!!ticklist.length && <TickList labels={ticklist} />}
          {!!footer?.length && <PortableText blocks={footer} />}
        </St.ContentBottom>
      )}
    </>
  );
};
