import { Formik, Form, Field, FormikHelpers } from 'formik';
import React, { useState, useContext } from 'react';

import Button from 'components/common/button';
import Container from 'components/common/container';
import FieldErrorMessage from 'components/common/forms/field-error-message';
import Input from 'components/common/forms/input';
import Label from 'components/common/forms/label';
import Select from 'components/common/forms/select';
import Textarea from 'components/common/forms/textarea';
import Section from 'components/common/section';
import { Settings } from 'contexts';

import { Fields, FormContainer, ButtonContainer } from './styles';
import { IContactFormFields, IContactFormProps } from './types';
import createZendeskRequest from './utils';

const initialValues: IContactFormFields = {
  name: '',
  email: '',
  phoneNumber: '',
  orderNumber: undefined,
  category: '',
  message: '',
};

export default function ContactForm({ successMessage }: IContactFormProps) {
  const { zendesk } = useContext(Settings);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (
    {
      name,
      email,
      orderNumber,
      category,
      message,
      phoneNumber,
    }: IContactFormFields,
    formHelpers: FormikHelpers<IContactFormFields>,
  ) => {
    try {
      if (!zendesk) return;
      setErrorMessage('');
      setSuccess(false);

      await createZendeskRequest({
        name,
        email,
        phoneNumber,
        orderNumber: !!orderNumber ? parseInt(orderNumber, 10) : undefined,
        category,
        message,
      });

      setSuccess(true);
      formHelpers.resetForm();
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  if (!zendesk) return null;

  const isOrderNumberMandatory = (fieldValue: string) =>
    zendesk.categories.find((category) => category.tag === fieldValue)
      ?.orderNumberMandatory || false;

  return (
    <Section padding="small">
      <Container>
        <FormContainer>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, getFieldProps }) => (
              <Form>
                <Fields>
                  <Label htmlFor="name">
                    Full name *
                    <Field
                      as={Input}
                      required
                      name="name"
                      id="name"
                      autocomplete="name"
                    />
                  </Label>

                  <Label htmlFor="email">
                    Email *
                    <Field
                      as={Input}
                      name="email"
                      id="email"
                      required
                      autocomplete="email"
                    />
                  </Label>

                  <Label htmlFor="phoneNumber">
                    Phone number
                    <Field
                      as={Input}
                      name="phoneNumber"
                      id="phoneNumber"
                      autocomplete="tel"
                    />
                  </Label>

                  <Label htmlFor="category">
                    What is your query about *
                    <Field
                      required
                      as={Select}
                      name="category"
                      id="category"
                      placeholder="Select"
                    >
                      {zendesk.categories.map(({ _key, tag, label }) => (
                        <option key={_key} value={tag}>
                          {label}
                        </option>
                      ))}
                    </Field>
                  </Label>

                  <Label htmlFor="orderNumber">
                    Order number
                    {isOrderNumberMandatory(getFieldProps('category').value)
                      ? ' *'
                      : ' (if applicable)'}
                    <Field
                      as={Input}
                      name="orderNumber"
                      id="orderNumber"
                      required={isOrderNumberMandatory(
                        getFieldProps('category').value,
                      )}
                    />
                  </Label>

                  <Label htmlFor="message">
                    Message *
                    <Field
                      as={Textarea}
                      required
                      name="message"
                      id="message"
                      autocomplete="off"
                      rows={12}
                    />
                  </Label>
                </Fields>
                <ButtonContainer>
                  <Button loading={isSubmitting} type="submit">
                    Submit
                  </Button>
                </ButtonContainer>
                {success && <p>{successMessage}</p>}
                {errorMessage && (
                  <FieldErrorMessage>{errorMessage}</FieldErrorMessage>
                )}
              </Form>
            )}
          </Formik>
        </FormContainer>
      </Container>
    </Section>
  );
}
