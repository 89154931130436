import styled, { css } from 'styled-components';

import { ButtonEl } from 'components/common/button/styles';
import { Flow, FlowX, FlowXFlex } from 'components/common/flow';
import {
  DESKTOP_NAV_HEIGHT,
  MOBILE_NAV_HEIGHT,
} from 'components/common/header/constants';
import MaskedIcon from 'components/common/masked-icon';
import { Heading1, Heading2 } from 'components/common/text';
import { TickListEl } from 'components/common/tick-list/styles';
import { ResponsiveVideoElement } from 'components/common/video/style';
import { desktop, mobile, tablet, tabletToDesktop } from 'utils/media';
import theme from 'utils/theme';

import { IHeroTwoMediaAtSproutlBreakpoint, THeroContainer } from './types';

// Hero wrappers

interface IContentWrapperProps {
  text: string;
  hideContent: boolean;
}

export const ContentWrapper = styled.div<IContentWrapperProps>`
  padding: var(--site-gutter);
  color: ${({ text }) => theme.colors[text]};
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${({ hideContent }) => (hideContent ? 0 : 1)};
  visibility: ${({ hideContent }) => (hideContent ? 'hidden' : 'visible')};
  transition: ${theme.transitionSpeeds.slow}s opacity,
    ${theme.transitionSpeeds.slow}s visibility;

  ${ButtonEl} {
    &:first-child,
    &:first-child:active {
      position: static;
      transform: none;
    }

    :first-child :after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
`;

export const MediaWrapper = styled.div`
  ${mobile} {
    ::before {
      display: block;
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
    }

    ::after {
      display: table;
      clear: both;
      content: '';
    }
  }
`;

interface IWrapperProps {
  background?: string;
  isPlayingPosteredVideo?: boolean;
  aspectRatios: IHeroTwoMediaAtSproutlBreakpoint[];
  container?: THeroContainer;
}

export const Wrapper = styled.div<IWrapperProps>`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: ${({ background }) => background && theme.colors[background]};

  ${tablet} {
    grid-template-columns: repeat(12, 1fr);
  }

  ${({ aspectRatios }) =>
    aspectRatios.map(
      ({ query, ratio, poster, horizontalAlignment, verticalAlignment }) => css`
        ${query} {
          ${ContentWrapper} {
            /* Handle the three types of horizontal positioning */
            ${horizontalAlignment === 'overlay'
              ? css`
                  grid-column: 1 / -1;
                  grid-row: 1 / -1;
                  text-align: center;

                  ${Body} {
                    margin-left: auto;
                    margin-right: auto;
                  }

                  ${CtaWrapper} {
                    justify-content: center;
                  }
                `
              : horizontalAlignment.endsWith('Half')
              ? css`
                  grid-column: span
                    ${{ [desktop]: 6, [tabletToDesktop]: 6, [mobile]: 2 }[
                      query
                    ] || 1};
                `
              : horizontalAlignment.endsWith('Third')
              ? css`
                  grid-column: span
                    ${{ [desktop]: 5, [tabletToDesktop]: 6, [mobile]: 1 }[
                      query
                    ] || 1};
                `
              : ''}

            /* Align the content vertically */
          justify-content: ${{
              top: 'flex-start',
              center: 'space-between',
              bottom: 'flex-end',
            }[verticalAlignment]};

            /* Step down the title size when it's displayed in a third-sized layout */
            ${horizontalAlignment.endsWith('Third') &&
            css`
              ${Heading1} {
                font-size: var(--step-7);
              }
            `}

            /* Center and wrap the ticklist in centered layouts */
        ${query !== mobile &&
            horizontalAlignment === 'overlay' &&
            css`
              ${TickListEl} {
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
              }
            `}  

        /* Hide play buttons when there's no poster */
        ${!poster?.length &&
            css`
              ${ButtonEl}:last-child:not(:first-child) {
                display: none;
              }
            `}

        ${verticalAlignment === 'center' &&
            css`
              ${ContentCenter}:only-child {
                margin-top: auto;
                margin-bottom: auto;
              }
            `}
          }

          ${MediaWrapper} {
            /* Sit the media & content on top of each other in overlay mode */
            ${horizontalAlignment === 'overlay'
              ? css`
                  grid-column: 1 / -1;
                  grid-row: 1 / -1;
                `
              : css`
                  /* Handle the various column widths for breakpoints & layout modes */
                  grid-column: span
                    ${query === mobile
                      ? 2
                      : query === tabletToDesktop
                      ? 6
                      : horizontalAlignment.endsWith('Half')
                      ? 6
                      : 7};
                  position: relative;

                  /* Use the padding-bottom hack for ratio-driven content, if not, let the image flow */
                  ${query === mobile && ratio.endsWith('%')
                    ? css`
                        ::before {
                          padding-bottom: ${ratio};
                        }
                      `
                    : query === mobile &&
                      css`
                        position: static;
                      `}
                `}

            /* Swap the image & content round when required */
        ${horizontalAlignment.startsWith('before') &&
            css`
              order: 1;
            `}
          }
        }
      `,
    )}
`;

export const AspectWrapper = styled.figure<IWrapperProps>`
  position: relative;
  display: flex;
  align-items: stretch;
  margin: 0 auto;
  ${({ container }) =>
    container &&
    css`
      max-width: ${{
        inset: theme.pageWidth.inset,
        normal: theme.pageWidth.standard,
        fullWidth: theme.pageWidth.extended,
      }[container]}px;
    `}

  ::before {
    display: block;
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
  }

  ::after {
    display: table;
    clear: both;
    content: '';
  }

  /* Use the padding-bottom hack for ratio-driven content */
  ${({ aspectRatios }) =>
    aspectRatios.map(
      ({ query, ratio }) =>
        ratio.endsWith('%') &&
        css`
          ${query} {
            ::before {
              padding-bottom: ${ratio};
            }
          }
        `,
    )}

  ${({ isPlayingPosteredVideo }) =>
    !!isPlayingPosteredVideo &&
    css`
      max-height: calc(100vh - ${DESKTOP_NAV_HEIGHT}px - 30px);

      ${mobile} {
        max-height: calc(100vh - ${MOBILE_NAV_HEIGHT}px - 30px);
      }

      ${ResponsiveVideoElement} {
        background: ${theme.colors.black};
        object-fit: contain;
      }
    `}
`;

export const ContentTop = styled.div``;

export const ContentCenter = styled(Flow)`
  ${Heading1},
  ${Heading2} {
    white-space: pre-line;
  }
`;

export const ContentBottom = styled(Flow)`
  padding-top: var(--site-gutter);

  p:last-child {
    margin-bottom: 0;
  }
`;

interface IBreakpointWrapperProps {
  query: string;
  ratio: string;
}

export const BreakpointWrapper = styled.div<IBreakpointWrapperProps>`
  display: none;

  ${({ query }) => query} {
    display: block;

    ${({ ratio }) =>
      ratio === 'image' &&
      css`
        &,
        & > *,
        img:first-child {
          position: static !important;
          height: auto !important;
        }
      `}
  }
`;

// Hero content

export const Body = styled(Flow)`
  font-size: var(--step-1);
  max-width: 50ch;
`;

interface ICloseButtonProps {
  colorScheme: string;
}

export const CloseButton = styled.button<ICloseButtonProps>`
  color: ${({ colorScheme = 'white' }) => theme.colors[colorScheme]};
  position: absolute;
  z-index: ${theme.zIndexes.mid};
  right: var(--site-gutter);
  top: var(--site-gutter);
`;

export const Eyebrow = styled(FlowX)`
  align-items: center;
  font: var(--step-2) ${theme.fonts.heading};
`;

export const StyledMaskedIcon = styled(MaskedIcon)`
  display: inline-block;
  width: 1em;
  height: 1em;
  mask-size: 1em;
  font-size: var(--step-3);
`;

export const CtaWrapper = styled(FlowXFlex)`
  --flow: var(--space-l-xl);
  --flow-x: var(--space-s);
`;

export const PrimaryLogo = styled.img`
  width: auto;
  height: 70px;
  max-width: 90%;
`;

export const SecondaryLogo = styled(PrimaryLogo)`
  height: 40px;
`;
