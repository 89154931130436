import { chunk, debounce } from 'lodash';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';

import { Chevron } from 'components/common/icons';
import Image from 'components/common/image';
import ResponsiveBox from 'components/common/responsive-box';
import { mobile, tablet } from 'utils/media';

import SocialCarouselVideo from './social-carousel-video';
import {
  Wrapper,
  Row,
  Block,
  MobileBlockImage,
  TabletBlockImage,
  BlockIndicator,
  Spacer,
  DotsRow,
  Dot,
  PageRow,
  PageArrow,
  RowOverflow,
  ServerState,
} from './styles';
import { SocialCarouselProps } from './types';

function MobileRowContent({ images, isMuted, setMute }: SocialCarouselProps) {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const innerRef = useRef<HTMLDivElement>(null);

  const scrollListener = useCallback(() => {
    if (innerRef.current) {
      setActiveItem(
        Math.floor(innerRef.current.scrollLeft / innerRef.current.offsetWidth),
      );
    }
  }, []);

  const jumpNext = useCallback(() => {
    if (typeof activeItem === 'number' && activeItem < images.length) {
      const nextEl = innerRef.current?.childNodes[activeItem + 2];
      if (nextEl) {
        (nextEl as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    }
  }, [activeItem, images.length]);

  useEffect(() => {
    scrollListener();
  }, [scrollListener]);

  return (
    <>
      <RowOverflow>
        <Row ref={innerRef} onScroll={scrollListener}>
          <Spacer />
          {images.map((image, index) =>
            image ? (
              <Block key={image._key}>
                <BlockIndicator>
                  {index + 1}/{images.length}
                </BlockIndicator>
                <MobileBlockImage>
                  <ResponsiveBox aspectRatio={1}>
                    {image._type === 'image' && (
                      <Image
                        src={image.asset}
                        aspectRatio={1}
                        alt={image.altText || ''}
                        sizes="calc(100vw - var(--space-2xl))"
                      />
                    )}
                    {image._type === 'video' && (
                      <SocialCarouselVideo
                        video={image.video}
                        aspectRatio={1}
                        active={activeItem === index}
                        altText={image.altText || ''}
                        hasSound={image.hasSound}
                        onEnded={jumpNext}
                        {...{ isMuted, setMute }}
                      />
                    )}
                  </ResponsiveBox>
                </MobileBlockImage>
              </Block>
            ) : null,
          )}
          <Spacer />
        </Row>
      </RowOverflow>
      <DotsRow>
        {images.map((_image, index) => (
          <Dot key={index} active={index === activeItem} />
        ))}
      </DotsRow>
    </>
  );
}

function TabletRowContent({ images, isMuted, setMute }: SocialCarouselProps) {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const innerRef = useRef<HTMLDivElement>(null);

  const blocks = useMemo(() => {
    const imageBlocks = [...images];

    /**
     * For landscape videos, insert null to move onto the next chunk
     */
    imageBlocks.forEach((item, index) => {
      if (item && item.landscape) {
        imageBlocks.splice(index + 1, 0, null);
      }
    });

    return chunk(imageBlocks, 2);
  }, [images]);

  const scrollListener = useCallback(() => {
    if (innerRef.current) {
      const offset =
        innerRef.current.scrollLeft /
        (innerRef.current.scrollWidth - innerRef.current.offsetWidth);

      setActiveItem(Math.round((blocks.length - 1) * offset));
    }
  }, [blocks.length]);

  const jumpPrevious = useCallback(() => {
    if (typeof activeItem === 'number' && activeItem >= 0) {
      const nextEl = innerRef.current?.childNodes[activeItem];
      if (nextEl) {
        (nextEl as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    }
  }, [activeItem]);

  const jumpNext = useCallback(() => {
    if (typeof activeItem === 'number' && activeItem < blocks.length) {
      const nextEl = innerRef.current?.childNodes[activeItem + 2];
      if (nextEl) {
        (nextEl as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    }
  }, [activeItem, blocks.length]);

  useEffect(() => {
    scrollListener();
  }, [scrollListener]);

  return (
    <>
      <RowOverflow>
        <PageRow>
          <PageArrow
            $disabled={typeof activeItem !== 'number' || activeItem <= 0}
            type="button"
            onClick={jumpPrevious}
          >
            <Chevron />
          </PageArrow>
          <PageArrow
            $disabled={
              typeof activeItem !== 'number' || activeItem >= blocks.length - 1
            }
            type="button"
            onClick={jumpNext}
          >
            <Chevron />
          </PageArrow>
        </PageRow>
        <Row ref={innerRef} onScroll={scrollListener}>
          <Spacer />
          {blocks.map((block, index) => (
            <Block key={index}>
              <BlockIndicator>
                {index + 1}/{blocks.length}
              </BlockIndicator>
              {block.map((image) =>
                image ? (
                  <TabletBlockImage
                    key={image._key}
                    $landscape={!!image.landscape}
                  >
                    <ResponsiveBox aspectRatio={image.landscape ? 0.5 : 1}>
                      {image._type === 'image' && (
                        <Image
                          src={image.asset}
                          aspectRatio={image.landscape ? 0.5 : 1}
                          alt={image.altText || ''}
                          sizes="500px"
                        />
                      )}
                      {image._type === 'video' && (
                        <SocialCarouselVideo
                          video={image.desktop_video || image.video}
                          aspectRatio={image.landscape ? 0.5 : 1}
                          active={activeItem === index}
                          onEnded={jumpNext}
                          hasSound={image.hasSound}
                          altText={image.altText || ''}
                          {...{ isMuted, setMute }}
                        />
                      )}
                    </ResponsiveBox>
                  </TabletBlockImage>
                ) : null,
              )}
            </Block>
          ))}
          <Spacer />
        </Row>
      </RowOverflow>
      <DotsRow>
        {blocks.map((_block, index) => (
          <Dot key={index} active={index === activeItem} />
        ))}
      </DotsRow>
    </>
  );
}

export default function SocialCarousel({ images }: SocialCarouselProps) {
  const [isMuted, setMute] = useState(true);
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const [clientSideLoaded, setClientSide] = useState(false);

  const checkPageWidth = useCallback(() => {
    setIsMobile(window.matchMedia(mobile.replace('@media', '')).matches);
  }, []);

  useEffect(() => {
    const debounced = debounce(checkPageWidth, 100);
    window.addEventListener('resize', debounced);
    setClientSide(true);
    checkPageWidth();

    return () => window.removeEventListener('resize', debounced);
  }, [checkPageWidth]);

  return (
    <Wrapper padding="small">
      {clientSideLoaded ? (
        <>
          {isMobile ? (
            <MobileRowContent {...{ images, isMuted, setMute }} />
          ) : (
            <TabletRowContent {...{ images, isMuted, setMute }} />
          )}
        </>
      ) : (
        <ServerState>
          <ResponsiveBox
            aspectRatio={{
              [mobile]: 1,
              [tablet]: 0.5,
            }}
          />
          <DotsRow>
            {[0, 1, 2].map((_, index) => (
              <Dot key={index} active={!index} />
            ))}
          </DotsRow>
        </ServerState>
      )}
    </Wrapper>
  );
}
