import { FC, Fragment, RefObject } from 'react';

import { DarkeningOverlay } from 'components/common/darkening-overlay';
import { Close } from 'components/common/icons';
import Image from 'components/common/image';
import BackgroundVideo from 'components/common/video/background-video';
import { desktop, mobile, tablet } from 'utils/media';

import * as St from '../styles';
import { EHeroTwoHorizontalAlignment, IHeroTwoLayer } from '../types';

export const HeroTwoMedia: FC<{
  layers?: IHeroTwoLayer[];
  colorScheme: string;
  controls: boolean;
  hasPoster: boolean;
  isTeaserRendered: boolean;
  videoRef: RefObject<HTMLVideoElement>;
  ratio: string;
  horizontalAlignment: EHeroTwoHorizontalAlignment;
  query: string;

  onClose: () => void;
  onPause: (timestamp: number) => void;
  onEnded: () => void;
}> = ({
  layers = [],
  colorScheme,
  controls,
  hasPoster,
  isTeaserRendered,
  ratio,
  horizontalAlignment,
  query,
  videoRef,
  onClose,
  onEnded,
  onPause,
}) => {
  const imageLayerCount = layers.filter(
    ({ _type }) => _type === 'image',
  ).length;

  // Overlay's are 100%, and all other format are 50% width till desktop
  let viewportWidth =
    query === mobile ||
    horizontalAlignment === EHeroTwoHorizontalAlignment.overlay
      ? 100
      : 50;

  if (query === desktop && horizontalAlignment.endsWith('Third')) {
    viewportWidth = 33.333;
  }

  const aspectRatio = ratio.endsWith('%')
    ? Number(ratio.replace('%', '')) / viewportWidth
    : null;

  return (
    <>
      {layers.map((layer, index) => {
        return layer._type === 'image' && layer?.asset?.metadata?.dimensions ? (
          <Image
            key={layer._key}
            alt=""
            src={layer}
            aspectRatio={
              aspectRatio ||
              layer.asset.metadata.dimensions.height /
                layer.asset.metadata.dimensions.width
            }
            objectFit={imageLayerCount > 1 && index ? 'contain' : 'cover'}
            sizes={`${viewportWidth}vw`}
          />
        ) : layer._type === 'video' ? (
          <Fragment key={layer._key}>
            <BackgroundVideo
              video={layer}
              preloadPoster={true}
              breakpoints={{
                [mobile]: {
                  width: 750,
                  height: Math.round(750 * (layer.height / layer.width)),
                },
                [tablet]: {
                  width: 1024,
                  height: Math.round(1024 * (layer.height / layer.width)),
                },
                [desktop]: {
                  width: 1920,
                  height: Math.round(1920 * (layer.height / layer.width)),
                },
              }}
              videoRef={videoRef}
              onEnded={onEnded}
              onPause={onPause}
              loop={!hasPoster}
              muted={!hasPoster}
              controls={controls}
            />
            {!isTeaserRendered && hasPoster && (
              <St.CloseButton
                type="button"
                onClick={onClose}
                colorScheme={colorScheme}
              >
                <Close />
              </St.CloseButton>
            )}
          </Fragment>
        ) : layer._type === 'shim' ? (
          <DarkeningOverlay key={layer._key} {...layer} />
        ) : null;
      })}
    </>
  );
};
