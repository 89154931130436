import { FC } from 'react';

import { IEmbedBlockProps } from '../types';

export const YouTubeEmbed: FC<Pick<IEmbedBlockProps, 'embedUrl'>> = ({
  embedUrl,
}) => {
  const url = new URL(embedUrl);
  let id: string | undefined | null = url.searchParams.get('v');
  if (!id && url.pathname.includes('shorts')) {
    id = url.pathname.split('shorts').at(-1);
  }

  if (!id) {
    return null;
  }

  return (
    <iframe
      src={`//www.youtube-nocookie.com/embed/${id}`}
      allowFullScreen
      frameBorder="0"
      title="YouTube Video"
    />
  );
};
