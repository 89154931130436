import Link from 'next/link';
import React from 'react';

import Button from 'components/common/button';
import Container from 'components/common/container';
import Image from 'components/common/image';
import ResponsiveBox from 'components/common/responsive-box';
import Section from 'components/common/section';
import { Heading2, HeadingWrapper, HeadingLink } from 'components/common/text';
import BackgroundVideo from 'components/common/video/background-video';
import { PortableText } from 'framework/sanity/portable-text';
import { desktop, largeDesktop, mobile, tablet } from 'utils/media';

import { calculateSize } from './helpers';
import * as St from './styles';
import { IContentCarouselProps } from './types';

export type { IContentCarouselProps };

export default function ContentCarousel({
  tiles,
  title,
  shape = 'portrait',
  titleLink = {},
}: IContentCarouselProps) {
  const { text, url } = titleLink;

  const aspectRatio = {
    portrait: 4 / 3,
    landscape: 3 / 4,
  }[shape];

  return (
    <Section bg="light">
      <Container noGutter noPadding>
        <HeadingWrapper>
          <Heading2 inline>{title}</Heading2>
          {text && url && (
            <Link href={url} passHref>
              <HeadingLink href={url}>{text}</HeadingLink>
            </Link>
          )}
        </HeadingWrapper>

        <St.CarouselRowStyled
          scrollBarReveal
          arrowLayout="overlay"
          removeScrollbarGap
        >
          {tiles?.map(({ body, colorScheme, cta, image, title, video }) => {
            return (
              <St.CarouselItemStyled
                data-testid="contentCarouselItem"
                key={title}
                size="xxlarge"
              >
                <St.SiteLinkStyled
                  as={cta?.url ? 'a' : 'div'}
                  href={cta?.url}
                  title={title}
                >
                  <St.TileBody colorScheme={colorScheme.text}>
                    <ResponsiveBox aspectRatio={aspectRatio} rounded>
                      {video ? (
                        <BackgroundVideo
                          video={video}
                          breakpoints={{
                            [mobile]: calculateSize(240, aspectRatio),
                            [tablet]: calculateSize(210, aspectRatio),
                            [desktop]: calculateSize(290, aspectRatio),
                            [largeDesktop]: calculateSize(390, aspectRatio),
                          }}
                        />
                      ) : image ? (
                        <Image
                          aspectRatio={aspectRatio}
                          src={image.media}
                          sizes={`${mobile} 240px, 390px`}
                          alt={image.altText || title}
                        />
                      ) : null}
                    </ResponsiveBox>

                    <St.TileTitle as="h3" colorScheme={colorScheme.text}>
                      {title}
                    </St.TileTitle>

                    {!!body?.length && (
                      <PortableText blocks={body} color={colorScheme.text} />
                    )}
                  </St.TileBody>

                  {cta?.title ? (
                    <Button
                      as="span"
                      colorScheme={
                        colorScheme.button !== 'primary'
                          ? colorScheme.button
                          : undefined
                      }
                      variant={
                        colorScheme.button === 'primary'
                          ? 'primary'
                          : 'secondary'
                      }
                    >
                      {cta.title}
                    </Button>
                  ) : null}
                </St.SiteLinkStyled>
              </St.CarouselItemStyled>
            );
          })}
        </St.CarouselRowStyled>
      </Container>
    </Section>
  );
}
