import React from 'react';

import { ErrorMessage } from './styles';

interface IFieldErrorMessageProps {
  children: string;
}

const FieldErrorMessage = ({ children }: IFieldErrorMessageProps) => (
  <ErrorMessage>{children}</ErrorMessage>
);
export default FieldErrorMessage;
