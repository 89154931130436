import styled from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface TextareaElProps {
  bg: 'white' | 'lightestGrey';
}

export const TextareaEl = styled.textarea<TextareaElProps>((props) => ({
  width: '100%',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey,
  backgroundColor: theme.colors[props.bg],
  padding: 'var(--space-s)',
  borderRadius: 'var(--space-xl)',
  minHeight: 100,
  resize: 'none',

  '&:focus': {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.purple,
    outline: 'none',
    boxShadow: `0 0px 0px 1px ${theme.colors.purple}`,
  },

  '&::placeholder': {
    color: theme.colors.midGrey,
    opacity: 1,
  },

  [desktop]: {
    padding: 'var(--space-s) var(--space-xl)',
  },
}));
