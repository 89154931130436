import { desktop, mobile, tabletToDesktop } from 'utils/media';

import {
  EHeroTwoHorizontalAlignment,
  EHeroTwoVerticalAlignment,
  IHeroTwoMediaAtBreakpoint,
  IHeroTwoMediaAtSproutlBreakpoint,
} from './types';

export const prepareHeroLayers = ({
  mobileBreakpoint,
  tabletBreakpoint,
  desktopBreakpoint,
  isPlayingPosteredVideo,
}: {
  mobileBreakpoint: IHeroTwoMediaAtBreakpoint;
  tabletBreakpoint: IHeroTwoMediaAtBreakpoint;
  desktopBreakpoint: IHeroTwoMediaAtBreakpoint;
  isPlayingPosteredVideo: boolean;
}): IHeroTwoMediaAtSproutlBreakpoint[] => {
  return [
    {
      query: mobile,
      breakpoints: [mobileBreakpoint],
    },
    {
      query: tabletToDesktop,
      breakpoints: [tabletBreakpoint, mobileBreakpoint],
    },
    {
      query: desktop,
      breakpoints: [desktopBreakpoint, tabletBreakpoint, mobileBreakpoint],
    },
  ].map(({ query, breakpoints }) => {
    return {
      ...breakpoints[0],
      query,
      ratio: isPlayingPosteredVideo
        ? '56.25%'
        : breakpoints.find((x) => !!x.minAspectRatio)?.minAspectRatio ||
          '56.25%',
      layers: breakpoints.find((x) => !!x.layers)?.layers || [],
      horizontalAlignment: isPlayingPosteredVideo
        ? EHeroTwoHorizontalAlignment.overlay
        : breakpoints.find((x) => !!x.horizontalAlignment)
            ?.horizontalAlignment || EHeroTwoHorizontalAlignment.overlay,
      verticalAlignment:
        breakpoints.find((x) => !!x.verticalAlignment)?.verticalAlignment ||
        EHeroTwoVerticalAlignment.center,
      poster: breakpoints?.[0]?.layers?.length
        ? breakpoints[0].poster || []
        : breakpoints.find((x) => !!x.poster)?.poster,
      controls: breakpoints.find((x) => !!x.controls)?.controls,
    };
  });
};

export const isSubscribeCTA = (url?: string): boolean => {
  return url === '{{ signup-popup }}';
};
