import { VideoSource } from './types';

export const pickSource = (sources: VideoSource[]) => {
  let newSource = null;

  if (typeof window !== 'undefined') {
    sources.forEach((source) => {
      if (window.matchMedia(source.media).matches) {
        newSource = source;
      }
    });
  }

  return newSource;
};
