import styled from 'styled-components';

import { FlowX } from 'components/common/flow/index';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Scrollable = styled(FlowX)`
  display: flex;
  overflow: auto;
  margin: 0 auto;
  padding: var(--space-xs) var(--site-gutter);
  white-space: nowrap;
`;
