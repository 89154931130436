import { FC } from 'react';

import { Tick } from 'components/common/icons';

import * as St from './styles';
import { ITickListItemProps } from './types';

export const TickListItem: FC<ITickListItemProps> = ({ label }) => {
  return (
    <St.TickListItem>
      <St.TickOutline>
        <Tick />
      </St.TickOutline>{' '}
      {label}
    </St.TickListItem>
  );
};
