import styled from 'styled-components';

import theme from 'utils/theme';

export const ErrorMessage = styled.span`
  font-size: var(--step--1);
  line-height: var(--space-l);
  color: ${theme.colors.errorRed};
  margin-left: var(--space-s);
`;
