import styled from 'styled-components';

import Section from 'components/common/section';
import { desktop, mobile } from 'utils/media';
import theme from 'utils/theme';

export const Wrapper = styled(Section)`
  --block-width: min(calc(100vw), 1000px);

  position: relative;
  margin-bottom: var(--site-gutter);
  padding-bottom: 0;
  font-size: 0;

  &:not(:first-child) {
    padding-top: 0;
  }

  ${mobile} {
    &:first-child {
      margin-top: calc(-1 * var(--space-2xs));
    }
  }
`;

export const RowOverflow = styled.div`
  overflow: hidden;
`;

export const Row = styled.div`
  overflow: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  padding-bottom: var(--space-6xl);
  margin-bottom: calc(-1 * var(--space-6xl));
`;

export const Block = styled.div`
  width: var(--block-width);
  display: inline-flex;
  justify-content: center;
  margin: 0 var(--space-m);
  scroll-snap-align: center;
  scroll-snap-stop: always;
  background-color: ${theme.colors.white};
  position: relative;

  ${desktop} {
    margin: 0 55px;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Spacer = styled.span`
  display: inline-block;
  width: calc((100vw - var(--block-width)) / 2);
`;

export const MobileBlockImage = styled.div`
  width: 100%;
  position: relative;
`;

interface TabletBlockImageProps {
  $landscape: boolean;
}

export const TabletBlockImage = styled.div<TabletBlockImageProps>`
  width: ${({ $landscape }) => ($landscape ? '100%' : '50%')};
  position: relative;
`;

export const BlockIndicator = styled.div`
  position: absolute;
  top: var(--space-xs);
  right: var(--space-xs);
  background-color: rgba(0, 74, 41, 0.7);
  border-radius: 60px;
  color: ${theme.colors.white};
  font-size: var(--step-0);
  z-index: ${theme.zIndexes.mid};
  padding: var(--space-3xs) var(--space-2xs);
  font-smooth: antialiased;
  cursor: default;
  user-select: none;
`;

export const DotsRow = styled.div`
  margin-top: var(--space-s);
  text-align: center;
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  font-size: 0;
  display: inline-block;
  margin: var(--space-3xs);
  width: var(--space-2xs);
  height: var(--space-2xs);
  background-color: ${theme.colors.darkGreen};
  border-radius: 100%;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
`;

export const PageRow = styled.div`
  position: absolute;
  pointer-events: none;
  width: calc(var(--block-width) - var(--space-2xl));
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 20;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1170px) {
    width: calc(var(--block-width) + 140px);
  }
`;

interface PageArrowProps {
  $disabled: boolean;
}

export const PageArrow = styled.button<PageArrowProps>`
  pointer-events: auto;
  background: ${theme.colors.white};
  border: 0;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.darkGreen};
  z-index: ${theme.zIndexes.mid};
  cursor: pointer;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 var(--space-4xs) ${theme.colors.darkGreen};
  }

  svg {
    transform: rotate(90deg);
  }

  &:last-child {
    svg {
      transform: rotate(-90deg);
    }
  }

  ${(props) => (props.$disabled ? `visibility: hidden;` : '')}
`;

export const ServerState = styled.div`
  width: var(--block-width);
  max-width: 100%;
  margin: 0 auto;
`;
