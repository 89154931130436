import React, { LabelHTMLAttributes, ReactNode } from 'react';

import { LabelEl } from './styles';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  children: ReactNode;
}

export const Label = ({ children, ...props }: LabelProps) => (
  <LabelEl {...props}>{children}</LabelEl>
);

export default Label;
