import styled from 'styled-components';

import theme from 'utils/theme';

export interface ILabelProps {
  bg?: 'white' | 'lightestGrey';
  color?: string;
}

export const Wrapper = styled.div`
  position: relative;
  height: ${theme.sizes.input.height};
`;

export const Label = styled.div.attrs((props: ILabelProps) => ({
  bg: props.bg || 'white',
  color: props.color || theme.colors.midGrey,
}))`
  width: 100%;
  height: ${theme.sizes.input.height};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.lightGrey};
  background-color: ${(props) => theme.colors[props.bg]};
  padding: 10px var(--space-s);
  border-radius: var(--space-2xl);
  color: ${(props) => props.color};
  font-size: ${theme.sizes.fonts.input};
  transition: color 0.1s ease-out;

  svg {
    color: ${theme.colors.black};
  }
`;

export const SelectEl = styled.select`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus + div {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.purple};
    box-shadow: 0 0px 0px 1px ${theme.colors.purple};
  }
`;
